import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from "../../components/project_page/ProjectPageWrapper"
import TextBlock from "../../components/project_page/TextBlock"
import Mobile3 from "../../components/project_page/Mobile3"
import Web1 from "../../components/project_page/Web1"
import Image1 from "../../components/project_page/Image1"
import ImageGrid from "../../components/project_page/ImageGrid"
import RollCallVid from "../../videos/jjacks.mp4"
import VideoWrap from "../../components/project_page/image_wrappers/VideoWrap"

import globalStyles from "../global.module.css"

const headerBackground = "#eefffd"
const navColor = "#222"
const RollCall = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="Rollcall"
      link={null}
      oneLiner="The fun way to stay connected."
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Problem"
          headline={`It's hard for family and friend groups that don't see eachother often to feel connected`}
          content={`People are busy. Chat groups can go stale for months if there
            is nothing prompting a group to stay connected. It's tough to get
            an 80+ year old grandparent and a 14 year old nephew in the same
            digital space.
          `}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <TextBlock
          title="The Product"
          headline={`Little meaningful moments that live between a group's big ones`}
          content={`An app where families and friend groups come
            together over fun daily challenges.`}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body1.childImageSharp.fluid} />}
          two={<Img fluid={data.body2.childImageSharp.fluid} />}
          three={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <VideoWrap backgroundColor={"#e8fbfa"} video={RollCallVid} />
      </div>

      <div className={`${globalStyles.marginBottomBig}`}>
        <div
          className={`${globalStyles.flex} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}
        >
          <div
            className={`${globalStyles.flexGrow} ${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}
          >
            <TextBlock
              title="Design"
              headline={`All together now`}
              content={
                <>
                  <p>
                    Our guiding design philosophy for Rollcall is inclusivity.
                    We want anyone in a family or friend group to be able to
                    participate regardless of technical savvy.
                  </p>
                  <p>
                    The upload flow is an example of this strategy where we made
                    very explicit the upload options both in terms of visibility
                    and explaination.
                  </p>
                  <p>
                    Writing "Record a video" instead of "Video" or just a camera
                    icon makes uploading clearer and more approachable.
                  </p>
                </>
              }
            />
          </div>
          <div
            className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}
          >
            <Image1 img={<Img fluid={data.body4.childImageSharp.fluid} />} />
          </div>
        </div>
      </div>
      <Image1
        maxWidth={"200px"}
        img={<Img fluid={data.body5.childImageSharp.fluid} />}
      />
    </ProjectPageWrapper>
  </Layout>
)

export default RollCall

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/rollcall/header.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeader: file(
      relativePath: { eq: "projects/rollcall/header-mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body1: file(relativePath: { eq: "projects/rollcall/app1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body2: file(relativePath: { eq: "projects/rollcall/app2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body3: file(relativePath: { eq: "projects/rollcall/app3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body4: file(relativePath: { eq: "projects/rollcall/app4.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body5: file(relativePath: { eq: "projects/rollcall/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
